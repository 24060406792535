import './ArticleRow.scss';
import {Link} from "react-router-dom";

export default function ArticleRow(props) {
    return (<>
        <div className={"card blog-card"}>
            <article className="mt-1">
                <Link to={`../article/${props.id}`}>
                    <img src={props.cover} className={'img-blog-card'} alt="Blog Cover Image"/>
                    <div className="text">
                        <p>{props.title}</p>
                    </div>
                </Link>
            </article>
        </div>
    </>)
}