import './App.scss';

import React, { useState } from "react";


import Portfolio from "./features/portfolio/Portfolio";

import { Theme, ThemeContext } from "./utils/context";
import * as ReactGA from "react-ga";
import {Bubbles} from "./shared/Bubbles";

const initReactGA = () => {
    ReactGA.initialize('UA-209766994-2');
    ReactGA.pageview('portfolio');
};

export default function App() {

    const pathname = window.location.pathname;

    initReactGA();

    const [theme, setTheme] = useState(Theme);

    console.log(pathname);

    if (theme === "light") {
        document.documentElement.style.setProperty("--textColor", "#000");
        document.documentElement.style.setProperty("--backgroundColor", "#FFF");
    } else if (theme === "dark") {
        document.documentElement.style.setProperty("--textColor", "#FFF");
        document.documentElement.style.setProperty("--backgroundColor", "#000");
    }

    return (
        <ThemeContext.Provider value={[theme, setTheme]}>
            <Portfolio />
        </ThemeContext.Provider>
    );
}