import React, {useEffect, useState} from "react";
import './Blog.scss';

import {FormattedMessage} from "react-intl";

import Constants from "../../../shared/utils/Constants";
import {collection, getDocs} from "firebase/firestore";
import Firestore from "../../../utils/firestore";
import ArticleRow from "./article-row/ArticleRow";
import * as _ from "lodash";

export default function Blog() {

    const [articles, setArticles] = useState([]);

    const chunkedArticles = _.chunk(articles, 4);

    const fetchArticles = async() => {
        const querySnapshot = await getDocs(collection(Firestore, "Articles"));
        querySnapshot.forEach((doc) => {
            console.log(doc.id)
            setArticles(articles => [...articles,  {...{"id" : doc.id}, ...doc.data() }]);
        });
    }

    useEffect(() => {
        fetchArticles().then();
    }, []);

    return (
        <div className={"container"}>
            <h1 className={"title"}>
                <FormattedMessage id="blog" defaultMessage={`${Constants.BLOG}`}/>
            </h1>

            <div className={"container m-1 scroll-bounce"}>

                { chunkedArticles.map((listArticles, index) =>
                    (<div className={'row'}>
                        { listArticles.map(article =>

                                <ArticleRow key={index}
                                            id={article.id}
                                            title={article.title}
                                            cover={article.cover}/>

                        )}
                    </div>)
                )}

            </div>


        </div>)
}