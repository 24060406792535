import './Article.scss';
import 'react-awesome-slider/dist/styles.css';

import {useParams} from "react-router-dom";
import {doc, getDoc} from "firebase/firestore";
import Firestore from "../../../../utils/firestore";
import React, {useEffect, useState} from "react";
import AwesomeSlider from "react-awesome-slider";

export default function Article(props) {

    let { id } = useParams();

    const [article, setArticle] = useState({});
    const [images, setImages] = useState([]);
    const [workoutStats, setWorkoutStats] = useState([]);

    const fetchArticle = async() => {
        const documentReference = doc(Firestore, "Articles", id);
        const documentSnapshot = await getDoc(documentReference);
        if (documentSnapshot.exists()) {
            const data = documentSnapshot.data();

            setArticle(data);
            setImages(data.newImages);
            setWorkoutStats(data.workoutStats);
        }
    }

    useEffect(() => {
        fetchArticle().then()
    }, []);



    return (<>

            <div className={'container'}>
                <h1 className={'title mb-3'}>{article.title}</h1>
                <div className={'m-2'} dangerouslySetInnerHTML={{__html: article.body}}/>

                <br/>

                <div className={'container mb-3'}>
                    {workoutStats?.length > 0 &&
                        <div className={'row center-row'}>
                            {workoutStats.map(image =>
                                <img className={'blog-img-middle'} src={image} alt={'picture'}/>
                            )}
                        </div>
                    }
                    {images?.length > 0 &&
                        <div className={'row center-row'}>
                            <div className={'m-3'}>
                                <AwesomeSlider fillParent={false}>
                                    {images.map(image =>
                                        <div>
                                            <img className={'blog-img-end'} src={image.url} width={`${image.width}%`}
                                                 alt={'picture'}/>
                                        </div>)}
                                </AwesomeSlider>
                            </div>
                        </div>
                    }
                </div>

            </div>

        </>
    );

}